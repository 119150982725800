<template>
  <div class="flex-1 flex flex-col justify-center max-w-[518px] text-[12px]">
    <div class="relative text-[28px] font-sans">
      <p class="font-medium">Daft</p>
      <p class="absolute top-0 text-red z-[-1] font-medium animate-leftToRight-hyper">Daft</p>
      <p class="absolute top-0 text-green z-[-3] font-medium animate-leftToRight-fast">Daft</p>
      <p class="absolute top-0 text-blue z-[-4] font-medium animate-leftToRight-medium">Daft</p>
      <p class="absolute top-0 text-pink z-[-2] font-medium animate-leftToRight-slow">Daft</p>
    </div>
    <div class="flex mt-6 text-left">
      <div class="w-[1px] bg-black mr-3" />
      <div class="my-[1px]">
        <span class="font-semibold">Date: </span>
        <span class="inline-block mb-1">June 27th, 2022</span>
        <br>
        <span class="font-semibold">Subject: </span>
        <span>The Way Out</span>
      </div>
    </div>
    <div class="mt-8" v-html="memo" />
    <div class="w-full h-[1px] bg-lightgrey mb-3 mt-6" />
    <div class="w-full text-[12px] flex justify-between">
      <p>{{ now.toLocaleTimeString('default' ) }}</p>
      <div class="flex gap-[10px] items-center">
        <p class="hover:underline cursor-pointer" @click="openTwitter()">TWTTR</p>
        <div class="w-[2px] h-[2px] bg-black rounded" />
        <p>MARKO@DAFT.XYZ</p>
      </div>
    </div>
  </div>
</template>

<script>
import { marked } from 'marked';
import markdown from '@/assets/markdown';
import { MARKO_TWITTER_URL } from '@/constants';

export default {
  name: 'Header',
  data() {
    return {
      now: new Date(),
    }
  },
  mounted() {
    setInterval(() => {
      this.now = new Date();
    }, 1000);
  },
  computed: {
    memo() {
      return marked(markdown.memo);
    },
    quote() {
      return marked(markdown.quote);
    }
  },
  methods: {
    openTwitter() {
      window.open(MARKO_TWITTER_URL, '_blank').focus();
    },
  }
}
</script>