<template>
  <div class="min-h-screen p-5 flex flex-col items-center justify-center">
    <Landing />
  </div>
</template>

<script>
import { Landing } from '@/components'

export default {
  name: 'App',
  components: {
    Landing,
  },
}
</script>